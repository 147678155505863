import Banner from "../components/Banner";
import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {imageUrl} from "gatsby-plugin-sanity-image";
import {SEO} from "../components/SEO";

export default function Index({data}) {
    return (
        <Layout title={"Home"} showNews>
            <Banner heightSmall={false} backgroundImage={'/media/mv-01.jpg'}>
                <div className={"banner-title"}>
                    <span style={{fontSize: "3rem", fontWeight: "bold"}}>Fine Ceramics and Equipment Engineering</span><br/>
                    <p style={{fontSize: "large", fontWeight: "500"}}>Made in Japan, to
                        the World</p>
                </div>
            </Banner>


            <div className="center" style={{background: "rgb(233, 239, 244)"}}>
                <div style={{
                    backgroundImage: "url(/logo-en.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    height: "50px",
                    width: "100%"
                }}/>
                <p style={{width: "min(500px, 100%)", textAlign: "center"}}>
                    Importing and exporting a variety of Japan-made quality engineering and fine ceramics related
                    products as well
                    as equipment from the U.S.A.
                </p>
            </div>
            <article className="section">
                <h2 style={{textAlign: "center"}}>Products</h2>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <p style={{width: "min(500px, 100%)", textAlign: "center"}}>
                        We offer a wide variety of products and production equipments related to Fine Ceramics.
                    </p>
                </div>
                <div style={{display: "grid"}} className="main-grid">
                    {
                        data.allSanityCategory.edges
                            .map(e => e.node)
                            .map(e => {
                                let imgUrl = e.image?.asset ? imageUrl(e.image?.asset) : 'https://placehold.jp/500x500.png?text=No%20Picture'
                                return (
                                    <a href={"/categories/" + e._id} className="item card"
                                       style={{backgroundImage: "url(" + imgUrl + ")"}}>
                                        <div className="card-opacity"></div>
                                        <div style={{flexGrow: "1"}}></div>
                                        <div className="label">
                                            <div className="subtitle">
                                                {e.title}
                                            </div>
                                        </div>
                                    </a>
                                )
                            })
                    }
                </div>
            </article>
        </Layout>
    )
}

export const query = graphql`
    query {
        allSanityCategory {
            edges {
                node {
                    _id
                    title
                    image {
                        ...ImageWithPreview
                    }
                }
            }
        }
    }
`

export const Head = () => {
    return (
        <SEO title={"Pacific Technology USA"}></SEO>
    )
}